.App {
  position: relative;
  color: white;
  padding: 0 120px;
}

.bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../Images/got-dragon.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: -50;
}

.mainHeader {
  margin-top: 120px;
}

.mainHeader h1 {
  font-size: 130px;
  font-family: 'Cormorant SC', serif;
  font-weight: 300;
  margin: 0;
}

.mainBottomPart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
}

.popularList p h3 {
  margin: 10px 0;
}

.popularList p {
  opacity: .7;
  font-size: 13px;
}

.storyArticle {
  width: 90%;
  max-width: 400px;
  font-weight: bold;
}

.storyArticle p {
  font-weight: 100;
  color: rgba(255, 255, 255, 0.849);
}

@media only screen and (max-width: 800px) {
  .App {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 600px) {
  .App {
    padding: 0 30px 30px 30px;
  }
  .mainHeader {
    margin-top: 30px;
  }
  
  .mainHeader h1 {
    font-size: 11vw;
  }

  .mainBottomPart {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
  }
  
  .popularList p h3 {
    margin: 10px 0;
  }
  
  .popularList p {
    opacity: .7;
    font-size: 13px;
  }
  
  .storyArticle {
    width: 90%;
    max-width: 400px;
    font-weight: bold;
  }
}

@media only screen and (max-width: 400px) {
  .mainHeader h1 {
    font-size: 10vw;
  }
}