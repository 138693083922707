@import url(https://fonts.googleapis.com/css?family=Cormorant+SC|Libre+Baskerville|Montserrat);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700,900&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: black;
  overflow-x: hidden;
}

.App {
  position: relative;
  color: white;
  padding: 0 120px;
}

.bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(/static/media/got-dragon.8fe8dd26.jpg) no-repeat center center fixed;
  background-size: cover;
  z-index: -50;
}

.mainHeader {
  margin-top: 120px;
}

.mainHeader h1 {
  font-size: 130px;
  font-family: 'Cormorant SC', serif;
  font-weight: 300;
  margin: 0;
}

.mainBottomPart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
}

.popularList p h3 {
  margin: 10px 0;
}

.popularList p {
  opacity: .7;
  font-size: 13px;
}

.storyArticle {
  width: 90%;
  max-width: 400px;
  font-weight: bold;
}

.storyArticle p {
  font-weight: 100;
  color: rgba(255, 255, 255, 0.849);
}

@media only screen and (max-width: 800px) {
  .App {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 600px) {
  .App {
    padding: 0 30px 30px 30px;
  }
  .mainHeader {
    margin-top: 30px;
  }
  
  .mainHeader h1 {
    font-size: 11vw;
  }

  .mainBottomPart {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
  }
  
  .popularList p h3 {
    margin: 10px 0;
  }
  
  .popularList p {
    opacity: .7;
    font-size: 13px;
  }
  
  .storyArticle {
    width: 90%;
    max-width: 400px;
    font-weight: bold;
  }
}

@media only screen and (max-width: 400px) {
  .mainHeader h1 {
    font-size: 10vw;
  }
}
.underlineParagraph {
  position: relative;
  cursor: pointer;
  display: table;
  transition: color 250ms 250ms;
}

.yAnim:before {
  content: " ";
  position: absolute;
  left: -10px;
  bottom: -10px;
  width: 0%;
  height: 3px;
  transition: width 250ms, height 250ms 250ms;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  z-index: -2;
}

.yAnim:hover:before {
  width: calc(100% + 20px);
  height: calc(100% + 20px);
}   

.yAnim:hover {
  color: black;
}   

.navBar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  z-index: 100;
}

.navBar * {
  cursor: pointer;
}
  
.playMusic img {
  width: 100px;
  cursor: pointer;
}

.navMenu div {
  display: flex;
  flex-direction: row;
}

.navMenu * {
  margin-left: 30px;
}
.playMusic {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileNav img {
  width: 30px;
  height: 30px;
}

.mobileNavMenu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 300;
  -webkit-backface-visibility: hidden;
}

.mobileNavMenu div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.mobileNavMenu p {
  text-align: center;
}

@media only screen and (max-width: 500px) {
  
  .lineParagraph p {
    font-size: 15px;
  }
  
  .navBar {
    position: relative;
    padding: 30px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    margin-top: 0;
  }
  
  .playMusic img {
    width: 70px;
    cursor: pointer;
  }

  .playMusic p {
    font-size: 12px;
  }

}
body {
    background-color: black;
}

h1 {
  font-family: 'Cormorant SC', serif;
  font-size: 80px;
  font-weight: 300;
  margin-bottom: 0;
}

.bg {
    width: 100vw;
    height: 100vh;
    background-size: cover;
}

.characterHeader {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.characterHeader span {
    cursor: pointer;
}

.here {
    text-decoration: underline;
}

.characterInfo {
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.characterInfo > ul{
    list-style-type: none;
}

.characterInfo li {
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.8);
    list-style-type: none;
    font-weight: 100;
}

.characterInfo span {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Cormorant SC', serif;
    color: white;
    cursor: auto;
}

.characterVideo {
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: black;
    overflow: hidden;
}

.video {
    width: 100%;
    height: 100%;
}

.show {
    display: all;
}

.hide {
    display: none;
}

.videoBack {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(27, 27, 27);
    z-index: 200; 
}

.shortCharacterInfo {
    margin-bottom: 100px;
}

.shortCharacterInfo>p {
    font-family: 'Montserrat', sans-serif;
    width: 50%;
}

.characterHeader button {
    height: 35px;
    border: white 3px solid;
    outline: none;
    background: none;
    color: white;
    padding: 8px 20px;
    cursor: pointer;
    font-weight: bolder;
    position: relative;
    transition: color 250ms, border-radius 250ms;
    margin: 20px;
}

.characterHeader button:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 0%;
    background-color: white;
    z-index: -2;
    transition: width 250ms;
}

.characterHeader button:hover:before {
    width: 100%;
}

.characterHeader button:hover {
    color: black;
    border-radius: 5px;
}

.shortCharacterInfo>div {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.watchVid {
    font-weight: 700;
}

.allCharactersBg {
    background: url(/static/media/got-characters.d7f22e40.jpg) no-repeat center center fixed;
    background-size: cover;
    z-index: -50;
}

.carousel {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    z-index: -2;
}

.characterItem {
    position: absolute;
    width: 400px;
    max-width: 400px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
}

.characterItem h2 {
    font-size: 50px;
    font-weight: 700;
}

.characterItem p {
    font-size: 13px;
    opacity: .8;
}

.arrowRotate {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.characterItemImage {
    width: 15vw;
    height: 15vw;
    border-radius: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.199);
}

.characterImgButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: -1;
}

.characterImgButtons img {
    padding: 30px;
}

button {
    z-index: 20;
}

.aboutBg {
    background: url(/static/media/white-walker.e53ddf8f.jpg) no-repeat center center fixed;
    background-size: cover;
}
.placeBg {
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
}

.placeHeader {
    position: relative;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 25vh;
}

.placeHeader h1 {
    margin: 0;
    padding: 0;
    z-index: 20;
}

.placeHeader img {
    padding: 20px;
    cursor: pointer;
}

.placeHeader img:nth-child(1){
    margin-right: 50px;
}

.placeHeader img:nth-child(3){
    margin-left: 50px;
}

.blockContainer {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    pointer-events: none;
}

.imgContainer {
    position: relative;
    max-width: calc(100% / 5);
    overflow: hidden;
}

.imgContainer > div {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    opacity: 1;
}

.secAnimBlock {
    position: relative;
    width: calc(100% / 5);
    background-color: white;
}

.rotatetArrow {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.placeDescription {
    width: 100%;
    max-width: 500px;
    text-align: center;
    margin: auto;
    margin-top: 20px;
    color: rgba(255, 255, 255, .9);
}
