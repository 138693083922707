@import url('https://fonts.googleapis.com/css?family=Cormorant+SC|Libre+Baskerville|Montserrat');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700,900&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: black;
  overflow-x: hidden;
}
