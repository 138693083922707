.underlineParagraph {
  position: relative;
  cursor: pointer;
  display: table;
  transition: color 250ms 250ms;
}

.yAnim:before {
  content: " ";
  position: absolute;
  left: -10px;
  bottom: -10px;
  width: 0%;
  height: 3px;
  transition: width 250ms, height 250ms 250ms;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  z-index: -2;
}

.yAnim:hover:before {
  width: calc(100% + 20px);
  height: calc(100% + 20px);
}   

.yAnim:hover {
  color: black;
}   

.navBar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  z-index: 100;
}

.navBar * {
  cursor: pointer;
}
  
.playMusic img {
  width: 100px;
  cursor: pointer;
}

.navMenu div {
  display: flex;
  flex-direction: row;
}

.navMenu * {
  margin-left: 30px;
}
.playMusic {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileNav img {
  width: 30px;
  height: 30px;
}

.mobileNavMenu {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 300;
  -webkit-backface-visibility: hidden;
}

.mobileNavMenu div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.mobileNavMenu p {
  text-align: center;
}

@media only screen and (max-width: 500px) {
  
  .lineParagraph p {
    font-size: 15px;
  }
  
  .navBar {
    position: relative;
    padding: 30px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    margin-top: 0;
  }
  
  .playMusic img {
    width: 70px;
    cursor: pointer;
  }

  .playMusic p {
    font-size: 12px;
  }

}