.placeBg {
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.placeHeader {
    position: relative;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 25vh;
}

.placeHeader h1 {
    margin: 0;
    padding: 0;
    z-index: 20;
}

.placeHeader img {
    padding: 20px;
    cursor: pointer;
}

.placeHeader img:nth-child(1){
    margin-right: 50px;
}

.placeHeader img:nth-child(3){
    margin-left: 50px;
}

.blockContainer {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    pointer-events: none;
}

.imgContainer {
    position: relative;
    max-width: calc(100% / 5);
    overflow: hidden;
}

.imgContainer > div {
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    opacity: 1;
}

.secAnimBlock {
    position: relative;
    width: calc(100% / 5);
    background-color: white;
}

.rotatetArrow {
    transform: rotate(180deg);
}

.placeDescription {
    width: 100%;
    max-width: 500px;
    text-align: center;
    margin: auto;
    margin-top: 20px;
    color: rgba(255, 255, 255, .9);
}