
.allCharactersBg {
    background: url(../Images/got-characters.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    z-index: -50;
}

.carousel {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    z-index: -2;
}

.characterItem {
    position: absolute;
    width: 400px;
    max-width: 400px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
}

.characterItem h2 {
    font-size: 50px;
    font-weight: 700;
}

.characterItem p {
    font-size: 13px;
    opacity: .8;
}

.arrowRotate {
    transform: rotate(180deg);
}

.characterItemImage {
    width: 15vw;
    height: 15vw;
    border-radius: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.199);
}

.characterImgButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: -1;
}

.characterImgButtons img {
    padding: 30px;
}

button {
    z-index: 20;
}