body {
    background-color: black;
}

h1 {
  font-family: 'Cormorant SC', serif;
  font-size: 80px;
  font-weight: 300;
  margin-bottom: 0;
}

.bg {
    width: 100vw;
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.characterHeader {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.characterHeader span {
    cursor: pointer;
}

.here {
    text-decoration: underline;
}

.characterInfo {
    width: 90%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.characterInfo > ul{
    list-style-type: none;
}

.characterInfo li {
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.8);
    list-style-type: none;
    font-weight: 100;
}

.characterInfo span {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Cormorant SC', serif;
    color: white;
    cursor: auto;
}

.characterVideo {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    overflow: hidden;
}

.video {
    width: 100%;
    height: 100%;
}

.show {
    display: all;
}

.hide {
    display: none;
}

.videoBack {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(27, 27, 27);
    z-index: 200; 
}

.shortCharacterInfo {
    margin-bottom: 100px;
}

.shortCharacterInfo>p {
    font-family: 'Montserrat', sans-serif;
    width: 50%;
}

.characterHeader button {
    height: 35px;
    border: white 3px solid;
    outline: none;
    background: none;
    color: white;
    padding: 8px 20px;
    cursor: pointer;
    font-weight: bolder;
    position: relative;
    transition: color 250ms, border-radius 250ms;
    margin: 20px;
}

.characterHeader button:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    height: 100%;
    width: 0%;
    background-color: white;
    z-index: -2;
    transition: width 250ms;
}

.characterHeader button:hover:before {
    width: 100%;
}

.characterHeader button:hover {
    color: black;
    border-radius: 5px;
}

.shortCharacterInfo>div {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.watchVid {
    font-weight: 700;
}